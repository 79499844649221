#main.page-calendar,
#main.page-customers,
#main.page-profile,
#main.page-invoice,
#main.page-invoices,
#main.print {
  width: 100%;
  min-width: 90vw;
  max-width: 100%;
  height: 100%;
  margin: 0;
}

#main.print {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0;
  padding: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  overflow: auto;
}

#main.print .invoice {
  padding: 0.25in 1in 1in;
}

#main.page-calendar {
  justify-content: flex-start;
}

#main.page-profile,
#main.page-invoice {
  height: auto;
}

#main.page-calendar #logo,
#main.page-profile #logo,
#main.page-invoice #logo,
#main.page-invoices #logo,
#main.page-customers #logo,
#main.page-calendar footer,
#main.page-profile footer,
#main.page-invoice footer,
#main.page-invoices footer,
#main.page-customers footer {
  display: none;
}

#main.page-invoice input {
  margin-top: 0;
}

#main.page-invoice .react-datepicker-wrapper {
  width: 100%;
}

#main.page-profile .profile-header > div > .panel {
  padding: 0;
}

#main.page-profile .profile-header .panel > section {
  padding: 2rem;
}

#main.page-profile .profile-header .panel > section {
  display: block;
  background: transparent;
}

#main.page-profile .profile-header .panel > section:first-of-type {
  border-bottom: 1px solid #9418ff50;
}

#main.page-profile .profile-header .panel > section:last-of-type {
  border-top: 1px solid #9418ff50;
}

#main.page-profile .profile-header .panel > section .panel {
  background: #9418ff25;
}

#main.page-profile .profile-header .panel > section p {
  color: black;
  margin: 0.5rem;
}

.modal {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: #ffffff80;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9000;
}

.modal > .overlay {
  position: absolute;
  min-width: 40rem;
  max-width: 50vw;
  padding: 1rem 2rem;
  border-radius: 1rem;
  background: white;
  box-shadow: 0 0 3rem #9418ff50;
}

.phone {
  display: inline-block;
  background: #9418ff20;
  padding: 0.5rem 1.5rem;
  border-radius: 100vw;
  font-size: 0.83em;
}

.sidebar-section .disclaimer {
  text-align: right;
  color: #9121f1;
}

.calendar-wrapper,
.customers-wrapper {
  flex: 1;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  left: 15.25rem;
  width: calc(100% - 16.5rem);
  margin: 0;
  padding: 0.5rem;
  overflow-y: auto;
}

.calendar-wrapper h2,
.customers-wrapper h2 {
  font-size: 1.6em;
  margin: 2rem 0 1rem;
  padding: 0;
  color: #171518;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.calendar-wrapper h2 i,
.customers-wrapper h2 i {
  display: none;
  font-style: normal;
  font-weight: bold;
  cursor: pointer;
  margin-right: 0.5rem;
  color: #9121f1;
}

.calendar-wrapper {
  margin: 0 0 2rem;
}

.calendar-wrapper > div,
.customers-wrapper:not(.invoice-builder) > div {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.customers-wrapper > .table span a {
  text-decoration: none;
}

.customers-wrapper > .table span a i {
  position: relative;
  display: inline-block;
  width: 25px;
  height: 25px;
  text-align: center;
  border-radius: 100vw;
  border: 2px solid #32be3f;
  color: #32be3f;
  text-decoration: none;
  font-style: normal;
  font-weight: bold;
  transition: all 0.25s ease;
}

.customers-wrapper > .table span a i:hover {
  background: #32be3f;
  color: white;
}

.customers-wrapper > .table .button-link {
  background: #9121f1;
  color: white;
  border-radius: 0.5rem;
  font-size: 0.8em;
  text-align: center;
  text-decoration: none;
  width: 5rem;
  justify-self: flex-end;
}

#main.page-calendar #nav,
#main.page-calendar footer > a,
#main.page-calendar footer > div:last-of-type,
#main.page-profile #nav,
#main.page-profile footer > a,
#main.page-profile footer > div:last-of-type,
#main.page-invoice #nav,
#main.page-invoice footer > a,
#main.page-invoice footer > div:last-of-type,
#main.page-customers #nav,
#main.page-customers footer > a,
#main.page-customers footer > div:last-of-type,
#main.page-invoices #nav,
#main.page-invoices footer > a,
#main.page-invoices footer > div:last-of-type {
  display: none !important;
}

#main.page-calendar section,
#main.page-customers section,
#main.page-invoice section,
#main.page-invoices section,
#main.page-profile section {
  margin: 0;
  padding: 0;
  border-radius: 0;
  width: 100%;
}

.page-profile > section,
.page-invoice > section {
  width: 100%;
}

.page-calendar .react-datepicker-wrapper {
  width: 100%;
}

.page-calendar input,
.page-calendar .react-datepicker-wrapper input {
  border-width: 2px;
}

.dashboard-heading {
  display: flex;
  align-items: center;
  width: 100%;
  text-transform: uppercase;
  font-size: 0.8em;
  text-align: left;
  margin: 2rem 0 0;
  color: #9418ff;
}

.dashboard-row {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin: 1rem;
  font-size: 0.8em;
}

.dashboard-row > * {
  flex: 1;
  max-width: 10rem;
  display: flex;
  align-items: center;
}

.dashboard-row input[type='checkbox'] {
  margin: 0 0.5rem 0 0;
}

.dashboard-row input[type='checkbox']:before {
  margin: 0;
}

.reload {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: .25rem;
  margin: 0;
  padding: .5rem;
  text-transform: uppercase;
  text-align: left;
  height: 2rem;
  border-radius: .5rem;
  vertical-align: middle;
  background: black;
}

.reload > span {
  font-size: .86em;
}

.reload > span:last-of-type {
  font-size: .66em;
}

.page-calendar .rbc-calendar {
  color: #9418ff;
  height: calc(100% - 5rem);
}

.page-calendar .rbc-date-cell {
  color: #333;
  margin: 0.5rem;
}

.page-calendar .rbc-event,
.page-calendar .rbc-day-slot .rbc-event {
  display: flex;
  flex-direction: column;
  align-content: flex-start;
  justify-content: center;
  border: none;
  margin: 0;
  background-color: inherit;
}

.page-calendar .rbc-event:active,
.page-calendar .rbc-event.rbc-selected {
  border: none;
  outline: none;
  background-color: #9121f1;
  margin-left: 0;
  margin-right: 0;
  border-radius: inherit;
}

.page-calendar .rbc-event-label,
.page-calendar .rbc-event-content {
  margin: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.page-calendar .rbc-current-time-indicator {
  height: 2px;
  background-color: #ff0060;
}

.page-calendar .rbc-show-more,
.page-calendar .rbc-show-more:hover,
.page-calendar .rbc-show-more:focus {
  background: white;
  padding: 0.25rem;
  margin: 0.25rem;
  color: #be9fff;
}

.page-calendar .rbc-button-link {
  font-weight: 400;
}

.page-calendar .rbc-today {
  background-color: #eac1ff50;
}

.page-calendar .rbc-now.rbc-current .rbc-button-link {
  color: #9418ff;
}

.page-calendar .rbc-off-range-bg {
  background-color: #e6ddf1;
}

.page-calendar .rbc-off-range .rbc-button-link {
  opacity: 0.5;
}

.page-calendar .rbc-day-slot .rbc-time-slot {
  border: none;
}

.page-calendar .rbc-day-bg,
.page-calendar .rbc-day-bg + .rbc-day-bg,
.page-calendar .rbc-month-row,
.page-calendar .rbc-month-row + .rbc-month-row,
.page-calendar .rbc-header,
.page-calendar .rbc-header + .rbc-header,
.page-calendar .rbc-month-view,
.page-calendar .rbc-timeslot-group {
  border-color: #ede4ff;
}

.page-calendar .rbc-btn-group {
  border: 2px solid #9923ff;
  border-radius: 100vw;
}

.page-calendar .rbc-btn-group button {
  border: none;
  color: #9923ff;
  border-radius: 100vw;
}

.page-calendar .rbc-toolbar {
  margin-bottom: 2rem;
}

.page-calendar .rbc-toolbar button:active,
.page-calendar .rbc-toolbar button:hover,
.page-calendar .rbc-toolbar button:focus,
.page-calendar .rbc-toolbar button:active:hover {
  background: none;
  color: #9923ff;
}

.page-calendar .rbc-btn-group button.rbc-active,
.page-calendar .rbc-toolbar button:active:hover,
.page-calendar .rbc-toolbar button:active:focus,
.page-calendar .rbc-toolbar button.rbc-active:hover,
.page-calendar .rbc-toolbar button.rbc-active:focus {
  background-color: #9923ff;
  border: none;
  color: white;
}

.primary-action-button {
  margin: 1rem 0 1rem auto;
  padding: 0.5rem 1rem;
  font-size: 0.9em;
}

.sidebar,
.sidebar-open {
  position: fixed;
  right: -25%;
  top: 0;
  bottom: 0;
  left: auto;
  width: 32rem;
  max-width: 25%;
  min-height: 46rem;
  background: white;
  padding: 1rem;
  text-align: center;
  z-index: 1000;
  transition-duration: 0.4s;
  transition-timing-function: ease;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.sidebar-open {
  box-shadow: 0 0 6rem #9418ff70;
  right: 0;
}

.sidebar.left,
.sidebar-open.left {
  right: auto;
  left: 0;
  width: 14rem;
  max-width: 14rem;
  box-shadow: none;
}

.sidebar.left::selection {
  background: none;
}

.sidebar-close-button {
  display: none;
}

.sidebar-section {
  position: relative;
  display: flex;
  justify-content: space-between;
  overflow: hidden;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100vh;
}

.sidebar-section .sidebar,
.sidebar-section .sidebar-open {
  position: absolute;
  flex: 1;
  background: #9418ff;
}

.sidebar-nav {
  list-style: none;
  text-align: left;
  padding: 1rem;
}

.sidebar-nav > li {
  display: flex;
  align-items: center;
  margin: 2rem 0;
}

.sidebar-nav > li span {
  margin-right: 0.5rem;
  font-weight: bold;
  font-size: 1.25em;
  color: transparent;
}

.sidebar-nav > li a,
.sidebar-nav > li.selected span {
  text-decoration: none;
  transition: opacity 0.25s ease;
  color: white;
}

.sidebar-nav > li.selected span {
  opacity: 0.5;
}

.sidebar-nav a:hover {
  opacity: 0.5;
}

.profile-invoices {
  list-style: none;
  padding: 0;
}

.profile-invoices > li {
  position: relative;
  background: #9418ff20;
  padding: 1rem;
  border-radius: 1rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 1rem;
  margin: 1rem auto;
  width: 100%;
}

.delete-button {
  position: absolute;
  display: block;
  background: none;
  color: #9418ff;
  margin: 0;
  padding: 0;
  width: 3rem;
  height: 3rem;
  right: 0;
}

.invoice-lines {
  width: 100%;
  list-style: none;
  margin: 1rem auto;
  padding: 0;
  background: #ffffff25;
  border-radius: 1rem;
}

.invoice.paid .invoice-lines {
  opacity: 0.75;
}

.invoice-line {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0.5rem;
}

.invoice-line.adjustments {
  font-style: italic;
  opacity: 0.6;
}

.invoice-line .delete-button {
  margin: 0;
  width: 1rem;
  height: 1rem;
  right: 1rem;
  left: auto;
}

.invoice-line.editing {
  gap: 1rem;
  padding: 0 1rem;
}

.invoice-line:nth-of-type(odd) {
  background-color: #9418ff20;
}

.invoice-line:first-of-type {
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
}

.invoice-line:last-of-type {
  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
}

.invoice {
  background: white;
  padding: 1rem;
  border-radius: 1rem;
  font:
    400 normal 12px 'Roboto',
    sans-serif;
}

.invoice h1 {
  color: black;
  font-size: 3.4em;
  font-weight: 600;
  letter-spacing: -2px;
  margin: 3rem 0 0.5rem;
}

.invoice h2 {
  color: black;
  font-size: 2em;
  font-weight: 200;
  line-height: 1em;
  margin: 2rem 0 1rem;
}

.invoice h3 {
  color: black;
  font-weight: 800;
  font-size: 1.25em;
  line-height: 1.5em;
  margin: 0;
}

.invoice p,
.invoice address {
  color: black;
  font-style: normal;
  line-height: 1em;
}

.invoice.paid {
  background: white url('https://www.slowblinkscatcare.com/paid-stamp.png')
    center center / contain no-repeat;
}

.invoice-table-heading {
  position: relative;
  display: flex;
  width: 100%;
  justify-content: flex-start;
  border-top: 1px solid #ccc;
  margin-top: 1rem;
  padding: 1rem 1rem 0;
}

ul.table {
  width: 100%;
  list-style: none;
  margin: 0;
  padding: 0;
  display: table;
}

.table > li {
  display: grid;
  grid-template-columns: 50px 1fr 1fr 0.25fr 0.25fr;
  grid-auto-rows: 1fr;
  padding: 0.6rem;
  gap: 0.6rem;
  align-items: center;
}

.table > li:nth-of-type(even) {
  background-color: #9418ff20;
}

.table > li.selected {
  background-color: #9418ff;
  color: white;
}

.table > li > * {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.table > li > a,
.table > li > strong {
  text-align: right;
  font-weight: 400;
}

.file-list,
.customers-wrapper > .panel.file-list {
  background: none;
  flex-direction: column;
  width: auto;
  margin: 1rem;
}

@media (max-width: 974px) {
  #main.page-calendar {
    margin: 0;
    height: 100vh;
  }

  .page-calendar > section {
    margin: 0;
  }

  .calendar-wrapper,
  .customers-wrapper,
  .page-profile section,
  .page-invoice section,
  .page-calendar .rbc-calendar {
    min-width: 0;
    width: 100%;
    left: 0;
  }

  .calendar-wrapper,
  .customers-wrapper {
    min-width: 300px;
  }

  .page-calendar .rbc-toolbar {
    flex-direction: column-reverse;
  }

  .page-profile section,
  .page-invoice section {
    padding: 1rem;
  }

  .page-calendar .rbc-toolbar-label {
    margin: 1rem auto;
  }

  .primary-action-button,
  .button {
    width: 100%;
  }

  #main.page-calendar footer,
  #main.page-customers footer {
    display: none;
  }

  .sidebar,
  .sidebar-open {
    width: 95%;
    max-width: 95%;
    right: -95%;
  }

  .sidebar.left {
    right: auto;
    left: -14rem;
  }

  .sidebar-open {
    right: 0;
  }

  .sidebar-open.left {
    right: auto;
    left: 0;
  }

  .calendar-wrapper h2 i,
  .customers-wrapper h2 i {
    display: inline-block;
  }

  .sidebar-close-button {
    display: block;
    background: white;
    color: #9418ff;
    position: absolute;
    bottom: 1rem;
    left: 0.5rem;
    margin: 0;
    right: 0.5rem;
  }

  .invoice-table-heading {
    display: none;
  }

  .invoice h3 {
    font-size: 1em;
  }

  .invoice h3,
  .invoice p,
  .invoice address {
    line-height: 1.5em;
  }

  .modal > .overlay {
    min-width: 90vw;
    max-width: 90vw;
  }

  .sidebar-section .disclaimer {
    text-align: center;
  }

  .dashboard-row {
    flex-direction: column;
    align-items: flex-start;
    margin: 2rem 1rem;
  }
}
