* {
  box-sizing: border-box;
}

::-webkit-scrollbar {
  width: 0.5rem;
  height: 0.5rem;
}

::-webkit-scrollbar-thumb {
  background: #9418ff50;
  border-radius: 100vw;
}

::-webkit-scrollbar-track {
  background: transparent;
}

body {
  margin: 0;
  font:
    400 normal 17px/24px 'Varela Round',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: flex;
  align-items: center;
  justify-content: center;
}

body,
#root {
  overflow-x: hidden;
  width: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
a {
  color: #9418ff;
  font-size: 1em;
  font-weight: 400;
}

h5,
.control {
  margin: 1rem 0 0;
}

textarea {
  resize: none;
  min-height: 10rem;
  padding: 0.5em;
}

section {
  display: flex;
  flex-direction: column;
  background: #f6eeff;
  margin: 1rem;
  padding: 2rem;
  overflow-x: hidden;
  overflow-y: auto;
  height: 100%;
  border-radius: 1rem;
}

textarea,
input[type='email'],
input[type='tel'],
input[type='number'],
input[type='address'],
input[type='text'],
select,
option {
  font-family: 'Varela Round', sans-serif;
  width: 100%;
}

input[type='checkbox'] {
  margin-left: 1.6rem;
}

input,
input[type='checkbox']:before,
textarea {
  border: 1px solid #9418ff;
  border-radius: 0.4rem;
  font-size: 1em;
  outline: none;
  margin-top: 0.5rem;
}

input[type='checkbox'] {
  -moz-appearance: none;
  -webkit-appearance: none;
  -o-appearance: none;
  appearance: none;
  outline: none;
  content: none;
  border: none;
}

input[type='checkbox']:before {
  content: '✔';
  color: transparent !important;
  background: transparent;
  display: block;
  width: 1rem;
  height: 1rem;
  line-height: 1rem;
  text-align: center;
}

input[type='checkbox']:checked:before {
  color: #9418ff !important;
}

.label-group {
  display: flex;
  align-items: center;
  font-size: 0.9em;
}

input[type='email'],
input[type='tel'],
input[type='number'],
input[type='address'],
input[type='text'] {
  width: 100%;
  padding: 0.5rem;
}

button,
.button {
  background: #9418ff;
  color: white;
  border: none;
  border-radius: 100vw;
  padding: 1rem 4rem;
  margin: 3rem auto;
  cursor: pointer;
  font:
    600 normal 1em 'Varela Round',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: block;
  text-decoration: none;
  text-align: center;
}

button[disabled],
.button[disabled],
input[disabled],
textarea[disabled] {
  opacity: 0.5;
  background: silver;
  cursor: default;
}

p.disclaimer {
  margin: 0.1rem 0;
  color: #8a78a1;
  font-size: 0.9em;
}

#book,
#submit {
  width: 100%;
}

#submit {
  margin: 1rem 0 0;
}

#close {
  background: none;
  padding: 0;
  display: inline-block;
  color: #9418ff;
  margin: 1rem auto 0;
  font-size: 0.84em;
}

#confirm {
  position: fixed;
  z-index: 1000;
  background: white;
  padding: 0 2rem 1rem;
  border-radius: 1rem;
  top: 10%;
  left: 15%;
  right: 15%;
  bottom: 10%;
  box-shadow: 0 0 3rem #9418ff50;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}

#confirm > h4 {
  display: block;
  width: 100%;
  text-align: center;
  margin: 1rem auto;
}

#confirm > div {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: 0.1rem auto;
  padding: 0.25rem;
}

#confirm > div:nth-of-type(odd) {
  background: #9418ff12;
}

#confirm > div > strong {
  text-align: left;
}

#confirm > div > div {
  text-align: right;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 50%;
}

#confirm .error {
  color: #ff0869;
  text-align: center;
  margin: 1rem auto;
  width: 100%;
}

#confirm.success {
  bottom: 40%;
  top: 40%;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

#back-button {
  background: transparent;
  color: #9418ff;
  position: absolute;
  left: 0;
  top: 0;
  padding: 0;
  margin: 0.5rem 1rem;
  font-size: 1.5em;
  line-height: 1.5em;
}

.panel {
  margin: 1rem 2rem;
  padding: 1rem;
  border-radius: 1rem;
  background: #ecd7ff;
}

.panel.full {
  margin: 2rem 0;
}

.panel h5 {
  margin: 0.5em 0;
}

.contact input:invalid {
  border-color: #ff0869;
}

.datepicker-label-group {
  display: flex;
  align-items: center;
}

.datepicker-label-group > div:first-of-type {
  margin: 0 0.5rem 0 0;
}

#main {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 300px;
  width: 60vw;
  max-width: 40rem;
  margin: 1rem auto 2rem;
}

#nav {
  display: block;
}

#nav,
#nav li {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
}

#nav li {
  margin: 0 0.5rem;
}

#nav li a[disabled] {
  pointer-events: none;
  text-decoration: none;
  opacity: 0.5;
}

#logo {
  display: block;
  margin: 1rem auto;
  object-fit: contain;
  max-width: 75%;
}

.react-datepicker__aria-live + input {
  width: 100%;
  font-family: 'Varela Round', sans-serif;
  padding: 0.5rem;
}

.time-picker {
  width: 25%;
  padding: 0.5rem;
}

.faq-item {
  margin: 1rem 0;
}

.faq-item h5 {
  font-weight: 400;
}

.intro {
  background: white;
  padding: 2rem;
  border-radius: 1rem;
  color: #bb9cff;
}

.intro h6 {
  margin: 1rem 0;
  color: black;
  color: #bb9cff;
}

.react-switch {
  vertical-align: middle;
  margin-left: 4px;
}

.react-switch-label {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin: 0.5rem 0;
}

.react-datepicker-popper[data-placement^='bottom'] .react-datepicker__triangle,
.react-datepicker-popper[data-placement^='top'] .react-datepicker__triangle {
  margin-left: -50%;
}

.photo-grid {
  display: flex;
  align-items: center;
}

.photo-grid > div {
  border-radius: 100vw;
  width: 6rem;
  height: 6rem;
  margin: 1rem;
}

.photo-grid > div:first-of-type {
  margin-left: 0;
}

.photo-grid > div > p {
  margin: 0;
  font-size: 0.8em;
  text-align: center;
}

.photo-grid > div > img {
  object-fit: cover;
  border-radius: inherit;
}

.accordion {
  display: flex;
  justify-content: space-between;
  align-content: center;
  cursor: pointer;
  margin-top: 1rem;
}

.accordion > p {
  line-height: 1em;
  transform: scaleX(1.5);
  color: rgb(187, 156, 255);
  margin: 1rem 0.5rem;
}

.question {
  border-bottom: 1px solid rgba(187, 156, 255, 0.5);
  margin: 1rem auto;
  padding-bottom: 2rem;
  width: 100%;
}

@media (max-width: 800px) {
  #main {
    width: 100%;
  }

  #confirm {
    top: 2%;
    left: 2%;
    right: 2%;
    bottom: 2%;
  }

  .label-group {
    align-items: start;
  }
}
